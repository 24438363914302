var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Ações"}},[_c('b-row',{staticClass:"my-1 text-right mt-2 d-flex align-items-center"},[_c('b-col',{staticClass:"d-flex",attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{staticClass:"flex-grow-1"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar item"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12","md":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.$router.push('/novo-projeto')}}},[_vm._v(" Novo Projeto + ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold text-primary",attrs:{"for":"category"}},[_vm._v("Status:")]),_c('b-form-select',{attrs:{"options":_vm.statusOptions,"value-field":"value","text-field":"name","placeholder":"Selecione um status"},on:{"change":_vm.getFilter},model:{value:(_vm.statusOptionSelected),callback:function ($$v) {_vm.statusOptionSelected=$$v},expression:"statusOptionSelected"}})],1)],1)],1)],1),_c('b-card',{attrs:{"title":("Projetos de: " + (_vm.currentWorkspace.workspace_title))}},[_c('b-table',{staticClass:"p-1 w-100",attrs:{"responsive":"sm","small":"","items":_vm.filter(),"fields":_vm.fields,"sort-desc":_vm.sortDesc,"per-page":10,"current-page":0,"show-empty":""},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('p',{staticClass:"text-center"},[_c('br'),_c('strong',[_vm._v("Não há dados para exibir")])]),_c('hr')])]},proxy:true},{key:"cell(project_code)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_code)+" "),_c('br'),_c('small',{style:({ color: 'red' })},[_vm._v(_vm._s(item.is_archived ? '(Arquivado)' : ''))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('Actions',{attrs:{"item":item},on:{"openUnDeleteModal":function($event){return _vm.openUnDeleteModal(item)},"openDeleteModal":function($event){return _vm.openDeleteModal(item)},"openDeleteProjectModal":function($event){return _vm.openDeleteProjectModal(item)}}})]}},{key:"cell(finish_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finish_date ? _vm.dateFormatter(item.finish_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}},{key:"cell(start_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_date ? _vm.dateFormatter(item.start_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}},{key:"cell(contract_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contract_date ? _vm.dateFormatter(item.contract_date, 'dd/MM/yyyy') : 'indefinido')+" ")]}}])}),_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":_vm.getPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('div',{staticClass:"mr-2 d-flex justify-content-end"},[_c('strong',[_vm._v("Total de projetos: "+_vm._s(_vm.totalRows)+" ")])])],1),_c('b-modal',{attrs:{"title":"Arquivar Projeto","hide-header-close":"","hide-footer":""},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('DeleteModal',{attrs:{"item":_vm.selectedItem,"exclusionType":_vm.exclusionType},on:{"closeDeleteModal":_vm.closeDeleteModal}})],1),_c('b-modal',{attrs:{"title":"Desarquivar Projeto","hide-header-close":"","hide-footer":""},model:{value:(_vm.unDeleteModal),callback:function ($$v) {_vm.unDeleteModal=$$v},expression:"unDeleteModal"}},[_c('UnDeleteModal',{attrs:{"item":_vm.selectedItem},on:{"closeUnDeleteModal":_vm.closeUnDeleteModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }